.cart-dropdown {
  position: absolute;
  width: 240px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 40px;
  z-index: 5;

  .empty-message {
    font-size: 18px;
    margin: 50px auto;
  }

  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #949494;
    }

    &:hover {
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000;
      }
    }
  }

  button {
    margin-top: auto;
  }
}

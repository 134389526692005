* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans Condensed", sans-serif;
  padding: 20px 80px;
}

a {
  text-decoration: none;
  color: black;
}
